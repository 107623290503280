.talks {
  flex-direction: column;
  margin-top: 3em;
}

.talks__desc {
  font-size: 1rem;
  font-weight: 500;
  max-width: 600px;
}

a {
  color: hsl(333deg, 100%, 45%);
}
